<!-- <div id="apps" class="our-apps pb-100"> -->
<div id="apps" class="our-apps ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="our-apps-img wow fadeInUp" data-wow-duration="1s">
                    <img src="assets/img/sass-landing/iphone.png" alt="iphone">
                </div>
            </div>
            <div class="col-lg-7">
                <div class="our-apps-text">
                    <div class="section-title text-start">
                        <span>Application mobile</span>
                        <h2>Téléchargez l'application Taskk</h2>
                    </div>
                    <p>Vous pouvez gérer votre entreprise en temps réel depuis votre téléphone, tandis que vos collaborateurs peuvent facilement accéder à la plateforme en déplacement grâce à notre application mobile.</p>
                    <div class="our-apps-btn">
                        <a routerLink="/" class="active">
                            <i class="flaticon-playstore"></i>
                            <p>Disponible sur</p>
                            <h5>Google Play</h5>
                        </a>
                        <a routerLink="/">
                            <i class="flaticon-app-store"></i>
                            <p>Disponible sur</p>
                            <h5>App Store</h5>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="apps-shape">
        <img src="assets/img/shape/1.png" alt="shape">
        <img src="assets/img/shape/3.png" alt="shape">
        <img src="assets/img/shape/5.png" alt="shape">
        <img src="assets/img/shape/6.png" alt="shape">
        <img src="assets/img/map-two.png" alt="shape">
    </div>
</div>