<div class="counter-section pt-100 pb-70">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-9 offset-lg-1">
                <div class="row">
                    <div class="col-lg-2 col-6 col-md-3">
                        <div class="counter-text">
                            <i class="flaticon-value"></i>
                            <h2><span class="counter">700</span>+</h2>
                            <p>Utilisateurs actifs</p>
                        </div>
                    </div>
                    <div class="col-lg-2 col-6 col-md-3">
                        <div class="counter-text">
                            <i class="flaticon-wallet"></i>
                            <h2><span class="counter">100</span>K+</h2>
                            <p>Factures générées</p>
                        </div>
                    </div>
                    <div class="col-lg-2 col-6 col-md-3">
                        <div class="counter-text">
                            <i class="flaticon-rocket"></i>
                            <h2><span class="counter">12</span>K+</h2>
                            <p>Projets terminés</p>
                        </div>
                    </div>
                    <div class="col-lg-2 col-6 col-md-3">
                        <div class="counter-text">
                            <i class="flaticon-clipboard"></i>
                            <h2><span class="counter">950K</span>+</h2>
                            <p>Tâches réalisées</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="counter-img wow" data-wow-duration="1s">
        <img src="assets/img/sass-landing/laptop.png" alt="laptop">
        <img src="assets/img/sass-landing/iphone.png" alt="laptop">
    </div>
</div>