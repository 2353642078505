import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { StickyNavModule } from 'ng2-sticky-nav';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TabsModule } from 'ngx-tabset';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { NgxSmartModalModule } from 'ngx-smart-modal';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SassLandingOneComponent } from './components/pages/sass-landing-one/sass-landing-one.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { SassOneBannerComponent } from './components/pages/sass-landing-one/sass-one-banner/sass-one-banner.component';
import { SassOneServicesComponent } from './components/pages/sass-landing-one/sass-one-services/sass-one-services.component';
import { SassOneWhyChooseComponent } from './components/pages/sass-landing-one/sass-one-why-choose/sass-one-why-choose.component';
import { SassOneStandardComponent } from './components/pages/sass-landing-one/sass-one-standard/sass-one-standard.component';
import { SassOneAppsComponent } from './components/pages/sass-landing-one/sass-one-apps/sass-one-apps.component';
import { SassOneCounterComponent } from './components/pages/sass-landing-one/sass-one-counter/sass-one-counter.component';
import { SassOnePricingComponent } from './components/pages/sass-landing-one/sass-one-pricing/sass-one-pricing.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { NewsletterComponent } from './components/common/newsletter/newsletter.component';

@NgModule({
    declarations: [
        AppComponent,
        SassLandingOneComponent,
        NavbarComponent,
        SassOneBannerComponent,
        SassOneServicesComponent,
        SassOneWhyChooseComponent,
        SassOneStandardComponent,
        SassOneAppsComponent,
        SassOneCounterComponent,
        SassOnePricingComponent,
        FooterComponent,
        NewsletterComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        StickyNavModule,
        BrowserAnimationsModule,
        CarouselModule,
        NgxSmartModalModule.forRoot(),
        TabsModule,
        NgxScrollTopModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }