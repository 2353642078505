<nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <img src="assets/img/logo.png" alt="logo">
        </a>
        <button class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto">
                <li class="nav-item"><span class="nav-link" (click)="onClick('home')">Accueil</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('services')">Fonctionnalités</span></li>
                <!-- <li class="nav-item"><span class="nav-link" (click)="onClick('about')">About Us</span></li> -->
                <li class="nav-item"><span class="nav-link" (click)="onClick('pricing')">Tarification</span></li>
                <!-- <li class="nav-item"><span class="nav-link" (click)="onClick('testimonials')">Testimonials</span></li> -->
                <li class="nav-item last">
                    <span></span>
                </li>
                <li class="nav-item">
                    <a href="https://www.app.taskk.io/#/login">
                        <span class="nav-link">CONNEXION</span>
                    </a>
                </li>
            </ul>
            <ul class="navbar-nav ms-auto for-responsive">
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('home')">Accueil</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('services')">Fonctionnalités</span></li>
                <!-- <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('about')">About Us</span></li> -->
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('pricing')">Tarification</span></li>
                <!-- <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('testimonials')">Testimonials</span></li> -->
                <li class="nav-item" (click)="toggleClass();">
                    <a href="https://www.app.taskk.io/#/login">
                        <span class="nav-link">CONNEXION</span>
                    </a>
                </li>
            </ul>
            <div class="navbar-btn">
                <a href="https://www.app.taskk.io/#/register">INSCRIPTION</a>
            </div>
        </div>
    </div>
</nav>