<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <img src="assets/img/logo.png" alt="logo">
                    </div>
                    <p>Boostez votre productivité et obtenez toutes les informations en temps réel sur la santé de votre entreprise.</p>
                    <div class="footer-link">
                        <a routerLink="/">
                            <img src="assets/img/google-play.png" alt="google image">
                        </a>
                        <a routerLink="/">
                            <img src="assets/img/app-store.png" alt="google image">
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-90">
                    <h3>Contact</h3>
                    <ul>
                        <li><p>support@taskk.io</p></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-90">
                    <h3>Liens utiles</h3>
                    <ul>
                        <li><span (click)="onClick('home')"><i class="flaticon-right"></i> Accueil</span></li>
                        <li><span (click)="onClick('services')"><i class="flaticon-right"></i> Fonctionnalités</span></li>
                        <!-- <li><span (click)="onClick('home')"><i class="flaticon-right"></i> Captures d'écran</span></li> -->
                        <li><span (click)="onClick('pricing')"><i class="flaticon-right"></i> Tarification</span></li>
                        <!-- <li><span (click)="onClick('home')"><i class="flaticon-right"></i> Témoignages</span></li> -->
                    </ul>
                </div>
            </div>
            <!-- <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-90">
                    <h3>Suivez-nous</h3>
                    <ul>
                        <li><a href="#" target="_blank"><i class="flaticon-right"></i> Facebook</a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-right"></i> Twitter</a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-right"></i> Linkedin</a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-right"></i> Instagram</a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-right"></i> Pinterest</a> </li>
                    </ul>
                </div>
            </div> -->
        </div>
    </div>
    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-4 lh-1">
                    <img src="assets/img/cmi.png" alt="cmi">
                    <img src="assets/img/visa.png" alt="visa card">
                    <img src="assets/img/master-card.png" alt="master card">
                </div>
                <div class="col-lg-6 col-md-8 lh-1">
                    <p>Taskk © 2023</p>
                </div>
            </div>
        </div>
    </div>
</footer>