<div id="about" class="why-choose why-choose-two pt-100 pb-70">
    <div class="container-fluid">
        <div class="section-title text-center">
            <span>Pourquoi Taskk ?</span>
            <h2>Nous fournissons les meilleures qualités</h2>
            <!-- <p>We always provide our best qualities ever. Our best qualities are a clean user interface, subheading, easy to use.</p> -->
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="why-choose-img wow fadeInUp" data-wow-duration="1s">
                    <img src="assets/img/sass-landing/laptop.png" alt="why choose image">
                </div>
            </div>
            <div class="col-lg-5">
                <div class="why-choose-text wow fadeInUp" data-wow-duration="1s">
                    <div class="media d-flex">
                        <i class="flaticon-computer mr-3"></i>
                        <div class="media-body">
                            <h3 class="mt-0">Simple et facile à utiliser</h3>
                            <p>Configurez vos projets en quelques minutes, sans formation préalable nécessaire.</p>
                        </div>
                    </div>
                    <div class="media d-flex">
                        <i class="flaticon-globe mr-3"></i>
                        <div class="media-body">
                            <h3 class="mt-0">Accessibilité</h3>
                            <p>Accessible depuis n'importe quel appareil connecté à Internet, ce qui facilite l'accès aux données de l'entreprise à tout moment et depuis n'importe où.</p>
                        </div>
                    </div>
                    <div class="media d-flex">
                        <i class="flaticon-relationship mr-3"></i>
                        <div class="media-body">
                            <h3 class="mt-0">Collaboration</h3>
                            <p>Avec l'accès mondial, les membres d'une équipe peuvent collaborer depuis des endroits éloignés.</p>
                        </div>
                    </div>
                    <div class="media d-flex">
                        <i class="flaticon-verified mr-3"></i>
                        <div class="media-body">
                            <h3 class="mt-0">Sécurité des données</h3>
                            <p>Les pannes matérielles n'entraînent pas de perte de données grâce aux sauvegardes réseau.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="shapes">
            <img src="assets/img/shape/1.png" alt="shape">
            <img src="assets/img/shape/2.png" alt="shape">
            <img src="assets/img/shape/3.png" alt="shape">
            <img src="assets/img/shape/4.png" alt="shape">
            <img src="assets/img/shape/5.png" alt="shape">
            <img src="assets/img/shape/6.png" alt="shape">
            <img src="assets/img/shape/7.png" alt="shape">
        </div>
    </div>
</div>