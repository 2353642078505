<div id="services" class="service-section pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Fonctionnalités</span>
            <h2>Obtenir un contrôle total sur votre entreprise</h2>
            <!-- <p>This feature is essential for any mobile app, so make sure you have it! These types of notifications can directly alert your user.</p> -->
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6 box">
                <div class="service-card service-card-six wow fadeInUp" data-wow-duration="1s" data-wow-delay=".6s">
                    <i class="flaticon-pie-chart"></i>
                    <h3>Dashboard</h3>
                    <p>Surveillez les données essentielles, prenez des décisions éclairées en toute confiance et ajustez votre stratégie pour optimiser la réussite de votre entreprise.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 box">
                <div class="service-card service-card-two wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
                    <i class="flaticon-writing"></i>
                    <h3>Facturation</h3>
                    <p>Créez rapidement vos devis et factures, que vous pourrez imprimer directement depuis l'application. Avec Taskk, simplifiez votre quotidien et gagnez en efficacité.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 box">
                <div class="service-card service-card-one wow fadeInUp" data-wow-duration="1s">
                    <i class="flaticon-wallet"></i>
                    <h3>Finances</h3>
                    <p>Gérez aisément vos dépenses et recettes, anticipez votre TVA en temps réel et suivez votre budget grâce à des rapports détaillés et clairs.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 box">
                <div class="service-card service-card-three wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                    <i class="flaticon-relationship"></i>
                    <h3>Projets et Collaborations</h3>
                    <p>Planifiez, organisez et suivez facilement vos projets. Vous pouvez gérer les tâches, les échéances, les ressources et les coûts associés, tout en bénéficiant d'une visibilité complète sur l'avancement de vos projets.</p>
                    <!-- <p>Simplifiez la gestion de vos tâches et projets. Attribuez des tâches à vos collaborateurs et suivez l'avancement et les coûts de chaque projet en travaillant en toute transparence.</p> -->
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 box">
                <div class="service-card service-card-four wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
                    <i class="flaticon-ticket"></i>
                    <h3>Gestion des équipements</h3>
                    <p>Gérez efficacement votre équipement. Suivez l'état de vos équipements, planifiez leur utilisation et optimisez leur maintenance. Un meilleur suivi pour un meilleur rendement.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 box">
                <div class="service-card service-card-five wow fadeInUp" data-wow-duration="1s" data-wow-delay=".5s">
                    <i class="flaticon-rocket"></i>
                    <h3>Données analytiques</h3>
                    <p>Analysez l'évolution de votre chiffre d'affaires, étudiez vos dépenses selon différents facteurs et identifiez vos clients les plus précieux. Désormais, toutes les informations essentielles de votre entreprise seront accessibles en temps réel, à portée de main.</p>
                </div>
            </div>
        </div>
    </div>
</div>