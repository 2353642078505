<div id="pricing" class="pricing-section price-section-two pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Tarification</span>
            <h2>Un abonnement clair qui s'adapte à l'évolution de votre entreprise</h2>
            <!-- <p>We are here with a smart pricing plan for you. We usually provide you with a monthly plan and annual plan.</p> -->
        </div>
        <div class="pricing-tabs">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="price-card active-price">
                        <p>Basic</p>
                        <h3>0<span class="currency"> MAD</span></h3>
                        <div class="discount-container">
                            <!-- <div class="pricing-yearly-cost-slash"></div>
                            <div class="pricing-yearly-cost-text"><p><span>100</span><span class="discount-currency"> MAD</span></p></div> -->
                        </div>
                        <div class="price-notes">
                            <!-- <span class="price-note">/ utilisateur / mois</span>
                            <span class="price-note">+ 300MAD / admin / mois</span>
                            <span class="price-interval">facturé annuellement</span> -->
                        </div>
                        <div class="price-feature">
                            <ul>
                                <li><i class="flaticon-checked"></i> Responsive</li>
                                <li><i class="flaticon-block"></i> Push Notifications</li>
                                <li><i class="flaticon-block"></i> 7/24 Support</li>
                                <li><i class="flaticon-block"></i> Unlimited Space</li>
                                <li><hr></li>
                                <li><i class="flaticon-checked"></i> 5 collaborateurs</li>
                                <li><i class="flaticon-checked"></i> 50 projets</li>
                            </ul>
                        </div>
                        <div class="price-btn">
                            <a href="https://www.app.taskk.io/#/register">6 MOIS D'ESSAI GRATUIT</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="price-card pro-class">
                        <p>Pro</p>
                        <h3>80<span class="currency"> MAD</span></h3>
                        <div class="discount-container">
                            <div class="pricing-yearly-cost-slash"></div>
                            <div class="pricing-yearly-cost-text"><p><span>100</span><span class="discount-currency"> MAD</span></p></div>
                        </div>
                        <div class="price-notes">
                            <span class="price-note">/ utilisateur / mois</span>
                            <span class="price-note">+ 300<span class="currency-admin">MAD</span> / admin / mois</span>
                            <span class="price-interval">facturé annuellement</span>
                        </div>
                        <div class="price-feature">
                            <ul>
                                <li><i class="flaticon-checked"></i> Responsive</li>
                                <li><i class="flaticon-checked"></i> Push Notifications</li>
                                <li><i class="flaticon-block"></i> 7/24 Support</li>
                                <li><i class="flaticon-block"></i> Unlimited Space</li>
                                <li><hr></li>
                                <li><i class="flaticon-checked"></i> Maximum 40 collaborateurs</li>
                                <li><i class="flaticon-checked"></i> Projets illimités</li>
                            </ul>
                        </div>
                        <div class="price-btn">
                            <a href="https://www.app.taskk.io/#/register">DÉMARRER</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                    <div class="price-card business">
                        <p>Business</p>
                        <!-- <h3>72 MAD<span> /Annual</span></h3> -->
                        <h3>72<span class="currency"> MAD</span></h3>
                        <div class="discount-container">
                            <div class="pricing-yearly-cost-slash"></div>
                            <div class="pricing-yearly-cost-text"><p><span>90</span><span class="discount-currency"> MAD</span></p></div>
                        </div>
                        <div class="price-notes">
                            <span class="price-note">/ utilisateur / mois</span>
                            <span class="price-note">+ 300<span class="currency-admin">MAD</span> / admin / mois</span>
                            <span class="price-interval">facturé annuellement</span>
                        </div>
                        <div class="price-feature">
                            <ul>
                                <li><i class="flaticon-checked"></i> Responsive</li>
                                <li><i class="flaticon-checked"></i> Push Notifications</li>
                                <li><i class="flaticon-checked"></i> 7/24 Support</li>
                                <li><i class="flaticon-checked"></i> Unlimited Space</li>
                                <li><hr></li>
                                <li><i class="flaticon-checked"></i> Collaborateurs illimités</li>
                                <li><i class="flaticon-checked"></i> Projets illimités</li>
                            </ul>
                        </div>
                        <div class="price-btn">
                            <a href="https://www.app.taskk.io/#/register">DÉMARRER</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>